import { useURLQuery } from 'Core/Hooks/useURLQuery'
import React, { createContext, useContext } from 'react'

/**
 * @type {{
 * routeTag: string
 * scopeKinds: import('types').ObjectMetaKinds[],
 * tagType: 'tag' | 'ptag'
 * tagItems: { tagKey:string, tagValue:string }[]
 * }} TagsURLContextType
 */

const INITIAL_VALUE = {
  routeTag: '',
  scopeKinds: [],
  tagType: 'tag',
  tagItems: []
}

const TagsURLQueryContext = createContext(INITIAL_VALUE)

/**
 * The context parses the url query.
 *
 * @param {*} param0
 * @returns
 */
const TagsURLQueryProvider = ({ children, scopeRouteKey = 'scope' }) => {
  const search = useURLQuery()

  const getDataFromURLQuery = () => {
    try {
      let tag = ''
      let tagType = ''
      const entries = search.entries()
      for (const element of entries) {
        tagType = element[0]
        tag = element[1]
        break
      }
      const tagScopeKinds = JSON.parse(search.get(scopeRouteKey))
      return { tag, tagScopeKinds, tagType }
    } catch (error) {
      return {}
    }
  }

  const getDataFromURLQueryMulti = () => {
    try {
      let urlTag = ''
      const entries = search.entries()

      for (const element of entries) {
        urlTag = element[1]
        break
      }
      const tags = JSON.parse(urlTag)
      const tagItems = tags.map((t) => {
        const split = t.split('!')
        const tagKey = split[0].trim()
        const tagValue = split[1]?.trim() || ''
        return { tagKey, tagValue }
      })
      return { tagItems }
    } catch (error) {
      return { tagItems: [] }
    }
  }

  const { tag, tagScopeKinds, tagType } = getDataFromURLQuery()

  const { tagItems } = getDataFromURLQueryMulti()

  return (
    <TagsURLQueryContext.Provider
      value={{
        routeTag: tag,
        scopeKinds: tagScopeKinds,
        tagItems,
        //@ts-ignore
        tagType
      }}
    >
      {children}
    </TagsURLQueryContext.Provider>
  )
}

export const useTagsURLQueryContext = () => useContext(TagsURLQueryContext)

export { TagsURLQueryProvider }
