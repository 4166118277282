import JSONbig from 'json-bigint'
import { reduxApiClient } from 'infra'

export const fetchGraphData = async (user, userObj, accountRefID, kind) => {
  const payload = {
    ObjectMeta: {
      Tenant: user.tenant,
      Namespace: user.org,
      Name: userObj?.Spec?.Name
    },
    Spec: {
      Type: 'GET_ACCOUNT_GRAPH',
      IdentityGraphSpec: {
        RefID: accountRefID,
        RefKind: kind,
        Level: 4
      }
    }
  }
  try {
    const response = await reduxApiClient('analyze-identity').create(payload)
    return JSONbig.parse(response?.Spec?.IdentityGraphSpec.Graph)
  } catch (error) {
    console.error('Error fetching graph data:', error)
    return []
  }
}

export const fetchNextGraphData = async (user, userObj, refID, kind) => {
  const payload = {
    ObjectMeta: {
      Tenant: user.tenant,
      Namespace: user.org,
      Name: userObj?.Spec?.Name
    },
    Spec: {
      Type: 'GET_IDENTITY_GRAPH',
      IdentityGraphSpec: {
        RefID: refID,
        RefKind: kind,
        Level: 4
      }
    }
  }
  try {
    const response = await reduxApiClient('analyze-identity').create(payload)
    return JSONbig.parse(response?.Spec?.IdentityGraphSpec.Graph)
  } catch (error) {
    console.error('Error fetching next graph data:', error)
    return []
  }
}

export const getIsCollapsibleState = (refkind, kind) => {
  return (
    refkind === 'IamUser' ||
    refkind === 'ExpandableNode' ||
    (refkind === 'IamGroup' && kind === 'IamGroup') ||
    (refkind === 'IamRole' && kind === 'IamRole') ||
    (refkind === 'IamRole' && kind === 'IamFederatedUser')
  )
}
