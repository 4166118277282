import { getRsrcTags } from 'features/iamResources'
import { getPolicyActionMap, getRsrcTagsMap, isPolicyIssuedToUser } from 'features/policy'
import { createRsrcKey, isResourceManaged } from 'features/resources'
import { getTargetsPolicyRules } from 'features/targets'
import { createDataSelectorHook } from 'infra/redux'

const useSlices = createDataSelectorHook(['policyList', 'serverList', 'applicationList'])

export const useTagsRsrcAccess = () => {
  const { slices } = useSlices()

  /**
   * Get all the tag pac policys belonging to `rsrc`
   * @param {*} rsrc The resource we need policy for
   * @param {*} user Incase we need policys for only this `user`
   * @returns {any[]}
   */
  const getRsrcTagPolicies = (rsrc, user = null) => {
    if (!rsrc) return []

    const TAGS = getRsrcTagsMap(rsrc)

    const policys = []

    slices.policyList.forEach((policy) => {
      if (user && !isPolicyIssuedToUser(policy, user)) return
      const { SSH } = getTargetsPolicyRules(policy)
      const HTTP = getPolicyActionMap(policy).HTTP?.PolicyRule || []
      const rules = [...HTTP, ...SSH]

      rules.forEach((rule) => {
        // tag pac policy always have empty ObjectRef
        if (rule.ObjectRef.RefKind !== '') return
        const tagsMap = rule.Attributes?.Map || {}
        const tags = Object.keys(tagsMap)
        for (const key of tags) {
          const [kindLowerCase, tagType, tagKey, tagValue] = key.split(':')
          // rsrc and pac tag kind *MUST* match
          if (kindLowerCase !== rsrc.ObjectMeta.Kind.toLowerCase()) return
          // get the appropriate tag map
          const rsrcTagMap = TAGS[tagType] || {}
          // split the values on rsrcTagMap[tagKey] with ',' Note: case insensitive
          const splitValues = (rsrcTagMap[tagKey] || '').toLowerCase().split(',')
          //* check if the pac's tag value matches the resources tags
          if (splitValues.includes(tagValue)) policys.push(policy)
        }
      })
    })

    return policys
  }

  const getTagPolicyRsrcs = (policy) => {
    const HTTPRules = getPolicyActionMap(policy).HTTP?.PolicyRule || []
    const SSHRules = getPolicyActionMap(policy).SSH?.PolicyRule || []

    const rules = [...HTTPRules, ...SSHRules]

    const rsrcs = []
    const _addedRsrcHistoryMap = {}

    rules.forEach((rule) => {
      const tagsMap = rule.Attributes?.Map || {}
      const tags = Object.keys(tagsMap)

      for (const key of tags) {
        const [kind, tagType, tagKey, tagValue] = key.split(':')
        let resourcesList = []

        switch (kind) {
          case 'server':
            resourcesList = slices.serverList.filter(isResourceManaged)
            break
          case 'application':
            resourcesList = slices.applicationList
          default:
            break
        }

        resourcesList.forEach((rsrc) => {
          const TAGS = getRsrcTagsMap(rsrc)

          // get the appropriate tag map
          const rsrcTagMap = TAGS[tagType] || {}
          // split the values on rsrcTagMap[tagKey] with ',' Note: case insensitive
          const splitValues = (rsrcTagMap[tagKey] || '').toLowerCase().split(',')
          //* check if the pac's tag value matches the resources tags (no duplicates)
          if (splitValues.includes(tagValue) && !_addedRsrcHistoryMap[createRsrcKey(rsrc)]) {
            _addedRsrcHistoryMap[createRsrcKey(rsrc)] = true
            rsrcs.push(rsrc)
          }
        })
      }
    })

    return rsrcs
  }

  return { getRsrcTagPolicies, getTagPolicyRsrcs }
}
