import { faInputText, faListDropdown } from '@fortawesome/pro-solid-svg-icons'
import { useProxyControllerWizardContext } from 'features/proxyControllers'
import { IconButton, LabelCheckbox, SelectDropDown, TextInput, Typography } from 'procyon-ui'
import React from 'react'
import { PrefixSuffixInput } from 'V2Components'

const TargetProxyImage = () => {
  const {
    targetProxyImages,
    updateConfig,
    rdpEnabled,
    targetProxyImagesOptions,
    customImageTagNames
  } = useProxyControllerWizardContext()

  const renderImageTagInput = (imageName) => {
    const tagValue = targetProxyImages[imageName].split(':')[1]

    const getTagNameMenuOptions = () => {
      const availableTags = [...targetProxyImagesOptions[imageName]]
      if (tagValue && !availableTags.includes(tagValue)) availableTags.push(tagValue)
      return availableTags
    }

    return (
      <div key={imageName} className='flex items-center justify-between gap-1'>
        {!customImageTagNames[imageName] && (
          <SelectDropDown
            style={{ width: '100%' }}
            menuItems={getTagNameMenuOptions().map((e) => ({
              label: e,
              value: e
            }))}
            onChange={(e) => {
              const val = e.target.value
              updateConfig({
                targetProxyImages: {
                  ...targetProxyImages,
                  [imageName]: `${targetProxyImages[imageName].split(':')[0]}:${val}`
                }
              })
            }}
            value={tagValue}
          />
        )}
        {customImageTagNames[imageName] && (
          <TextInput
            className='!h-10'
            value={tagValue}
            onChange={(e) => {
              const val = e.target.value
              updateConfig({
                targetProxyImages: {
                  ...targetProxyImages,
                  [imageName]: `${targetProxyImages[imageName].split(':')[0]}:${val}`
                }
              })
            }}
          />
        )}
        <IconButton
          icon={customImageTagNames[imageName] ? faInputText : faListDropdown}
          onClick={() => {
            updateConfig({
              customImageTagNames: {
                ...customImageTagNames,
                [imageName]: !customImageTagNames[imageName]
              }
            })
          }}
          variant={customImageTagNames[imageName] ? 'grayBlue' : 'grayBlue'}
        />
      </div>
    )
  }

  return (
    <div className=''>
      <div className='mt-4'>
        <Typography className='mb-1' variant='body-regular'>
          Procyon Proxy Image
        </Typography>
        {/** @ts-ignore */}
        <PrefixSuffixInput
          prefixAsInput
          prefixWidth='200%'
          prefix={`procyon-proxy:${targetProxyImages['procyon-proxy'].split(':')[0]}`}
          CustomValueComponent={renderImageTagInput('procyon-proxy')}
        />
      </div>
      <LabelCheckbox
        checked={rdpEnabled}
        name='RDP Enabled'
        onClick={() => updateConfig({ rdpEnabled: !rdpEnabled })}
      />
      {rdpEnabled && (
        <div>
          {RDP_IMAGES.map(({ title, prefix }) => {

            return (
              <div className='mt-4' key={prefix}>
                <Typography className='mb-1' variant='body-regular'>
                  {title}
                </Typography>
                <PrefixSuffixInput
                  prefixAsInput
                  prefixWidth='200%'
                  prefix={`${prefix}:${targetProxyImages[prefix]?.split(':')[0]}`}
                  value={targetProxyImages[prefix]?.split(':')[1]}
                  CustomValueComponent={renderImageTagInput(prefix)}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const RDP_IMAGES = [
  {
    title: 'Guacamole Client Image',
    prefix: 'guacamole-client'
  },
  {
    title: 'Guacamole Server Image',
    prefix: 'guacamole-server'
  },
  {
    title: 'Procyon FreeRDP Image',
    prefix: 'procyon-freerdp'
  }
]

export { TargetProxyImage }
