import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { getPolicyType, isTagBasedPolicy } from 'features/policy/utils'
import React from 'react'
import { useEditPolicyModulesContext } from '../../EditPolicyModulesProvider'
import { IAMAction } from './components/IAMAction'
import { Target } from './components/Target'
import { TagBased } from './components/TagBased'

const ConfigureResources = ({ iamActionsRsrcMap, setIamActionsRsrcMap }) => {
  const { policy } = useEditPolicyModulesContext()

  useHTMLBodyScrollbar(true)
  const policyType = getPolicyType(policy)
  const isTagPolicy = isTagBasedPolicy(policy)

  return (
    <div>
      {isTagPolicy && <TagBased />}
      {!isTagPolicy && (
        <>
          {policyType === 'IAMAction' && (
            <IAMAction
              iamActionsRsrcMap={iamActionsRsrcMap}
              setIamActionsRsrcMap={setIamActionsRsrcMap}
            />
          )}
          {policyType === 'Target' && <Target />}
        </>
      )}
    </div>
  )
}

export { ConfigureResources }
