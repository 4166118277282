import {
  createRef,
  createRsrcKey,
  getResourceName,
  getResourcePolicyTags,
  keysToLowerCase
} from 'features/resources'
import _ from 'lodash'
import { IAMActionActionMapKeys } from './constants'
import { getTargetsPolicyRules } from 'features/targets'
import { getObjectFromRedux } from 'infra/redux'
import { getRsrcTags } from 'features/iamResources'

/**
 * Returns valid policy actions map from OrgSpec or Spec when old policy is used
 * @param {any} policy
 */
export const getPolicyActionMap = (policy) => {
  if (!policy) return {}
  if (policy.Type !== 'IAMAction') return policy.Spec.ActionMap
  if (_.isEmpty(policy.OrgSpec.ActionMap)) return policy.Spec.ActionMap
  return policy.OrgSpec.ActionMap
}

/**
 * @param {*} policy
 * @returns {import('types').ObjectRef[]} Returns the resources included in this policy
 */
export const getPolicyResourcesRefs = (policy) => {
  const actions = [
    'AssumeRole',
    'SSH',
    'DBAccess',
    'AwsIAM',
    'GcpIAM',
    'AzureIAM',
    'HTTP',
    'GithubAccess',
    'RDPAccess',
    'Kafkas',
    'RDPServer',
    'KafkaAccess',
    'KubeAccess',
    'Salesforce',
    'Snowflake',
    'Databricks'
  ]
  const rsrcsRefs = []
  const tempMap = {}

  actions.forEach((action) => {
    const actionMap = getPolicyActionMap(policy)
    const rules = actionMap[action]?.PolicyRule
    if (!rules || !rules.length) return
    rules.forEach((rule) => {
      if (
        (rule.Services.ObjectRef?.length && policy.Type === 'IAMAction') ||
        policy.Type === 'GithubResources'
      ) {
        // IAM Resource policy
        rsrcsRefs.push(
          ...rule.Services.ObjectRef.filter((e) => {
            // Check if the rsrc ref is already added in the list
            if (!tempMap[createRsrcKey(e)]) {
              tempMap[createRsrcKey(e)] = true
              return true
            }
            return false
          })
        )
      } else {
        //Targets policy
        // Check if the rsrc is already added in the list
        if (!tempMap[createRsrcKey(rule.ObjectRef)]) {
          tempMap[createRsrcKey(rule.ObjectRef)] = true
        }
        rsrcsRefs.push(rule.ObjectRef)
      }
    })
  })

  return rsrcsRefs
}

/**
 * Returns the IssuedTo of a policy
 * @param {*} policy
 * @returns {any[]}
 */
export const getPolicyIssuedToEntities = (policy) => {
  return policy?.IssuedTo.ObjectRef || []
}

/**
 * Get errors for policy
 * @param {*} policy
 * @returns Error object or null if no errors
 */
export const getPolicyError = (policy) => {
  try {
    const Error = JSON.parse(policy?.Status?.Error)
    if (Object.keys(Error).length) return Error
  } catch (error) {
    return null
  }
  return null
}

/**
 * Function to check if a policy is issued to user
 * @param {*} policy The policy to check
 * @param {*} user The user to check
 * @returns `true` if the `policy` is issued to `user`
 */
export const isPolicyIssuedToUser = (policy, user) => {
  if (!policy || !user) return
  const IssuedTo = policy.IssuedTo.ObjectRef
  const groupsRefs = user.Spec.Groups?.ObjectRef || []
  /**
   * Check if the policy is issued to the current user - regardless of the user as an admin or non-admin
   * Even admins don;t have access to IAM Resources until it is issued to the admin
   */
  if (_.find(IssuedTo, createRef(user)) || groupsRefs.some((ref) => _.find(IssuedTo, ref)))
    return true
  return false
}

/**
 * Function to check if a policy is issued to a group
 * @param {*} policy The policy to check
 * @param {*} group The group to check
 * @returns `true` if the `policy` is issued to `group`
 */
export const isPolicyIssuedToGroup = (policy, group) => {
  if (!policy || !group) return
  const IssuedToGroups = policy.IssuedTo.ObjectRef.filter((e) => e.RefKind === 'Group')
  /**
   * Check if the policy is issued to the group
   */
  if (_.find(IssuedToGroups, createRef(group))) return true
  return false
}

/**
 * Function to check if a policy is issued to a entity
 * @param {*} policy The policy to check
 * @param {*} entity The entity to check
 * @returns `true` if the `policy` is issued to `entity`
 */
export const isPolicyIssuedToEntity = (policy, entity) => {
  if (!policy || !entity) return
  const IssuedToGroups = policy.IssuedTo.ObjectRef
  /**
   * Check if the policy is issued to the group
   */
  if (_.find(IssuedToGroups, createRef(entity))) return true
  return false
}

/**
 * The function returns all the IamActions which are attached to the resources on the policy
 * Only, Aws is handled
```
  {
    "AwsResource+123": [{ RefKind: "IamAction", RefID: "12589" }]
  }
```
 * @param {*} policy
 * @returns
 */
export const getPolicyAttachedIAMActions = (policy) => {
  if (!policy) return {}
  /**
   * Will hold the key of the resource with array of iam actions Ref object
   * Eg, mapObj =  {
   *    "AwsResource+123": [{ RefKind: "IamAction", RefID: "12589" }]
   * }
   * */
  const mapObj = {}
  const ActionMap = getPolicyActionMap(policy)
  const actions = Object.keys(ActionMap)
  /**
   * Checking if the policy has the actions map in them,
   * If not then, there are no iam actions to lookup
   */
  if (!actions.some((e) => IAMActionActionMapKeys[e])) return {}
  /**
   * Loop through actions which holds the iam actions attached
   */
  Object.keys(IAMActionActionMapKeys).forEach((action) => {
    const PolicyRule = ActionMap[action]?.PolicyRule
    if (!PolicyRule) return
    PolicyRule.forEach(({ ObjectRef, Services }) => {
      if (ObjectRef.RefKind !== 'IamAction') return
      Services.ObjectRef.forEach((rsrcRef) => {
        const key = createRsrcKey(rsrcRef)
        const iamActions = mapObj[key]
        if (iamActions) {
          const actionExists = iamActions.find(
            (e) => e.RefKind === ObjectRef.RefKind && e.RefID === ObjectRef.RefID
          )
          // Check if the IamAction exists in the array, if not then add
          if (!actionExists) mapObj[key].push(ObjectRef)
        } // add iam action to the rsrc key
        else mapObj[key] = [ObjectRef]
      })
    })
  })
  return mapObj
}

/**
 * The function checks if the policy created an approle. If an approle was created then, the AssumeRole will have ObjectRef of approle
 * The approle is only fetched if the policy is for IAM Actions
 * Note: Only AwsResources policy results in approle creation
 * @returns approle ref attached to this policy
 */
export const getAWSResourcePolicyAppRole = (policy) => {
  if (!policy || policy.Type !== 'IAMAction') return
  const policyRule = policy.Spec.ActionMap.AssumeRole?.PolicyRule?.[0]
  if (!policyRule || policyRule.ObjectRef.RefKind !== 'AppRole') return
  return policyRule.ObjectRef
}

/**
 * The function returns a map of resource with all associated iam actions which are there in the `policys`
 * @param {any[]} policys
 * @returns Map of attached iam actions with rsrcs
 */
export const getCombinedRsrcIAMActionMap = (policys = []) => {
  // Creates a map of combined resource with actions
  const actions = policys.reduce((prev, policy) => {
    const iamActionsMap = getPolicyAttachedIAMActions(policy)
    for (const rsrcKey in iamActionsMap) {
      const addedActions = prev[rsrcKey] || []
      const acts = iamActionsMap[rsrcKey]
      addedActions.push(...acts)
      prev[rsrcKey] = addedActions
    }
    return prev
  }, {})

  // Remove any duplicate actions from the resource
  for (const rsrcKey in actions) {
    const map = {}
    const acts = actions[rsrcKey]
    actions[rsrcKey] = acts.filter((a) => {
      const key = createRsrcKey(a)
      if (!map[key]) {
        map[key] = true
        return true
      }
    })
  }

  return actions
}

/**
 * Check if a policy is issued to a workload identity
 * @param {*} policy
 */
export const isWorkloadIdentityPolicy = (policy) => {
  const entities = getPolicyIssuedToEntities(policy)
  return entities.some((e) => e.RefKind === 'WorkloadIdentity')
}

/**
 * Check is a policy edit is currently supported in UI
 * @param {*} policy
 * @returns {boolean}
 */
export const isPolicyEditable = (policy) => {
  const EDIT_POLICY_TYPES = ['Target', 'IAMAction']
  if (!EDIT_POLICY_TYPES.includes(policy.Type)) return false
  if (policy.Type === 'IAMAction') return Object.keys(policy.OrgSpec.ActionMap).length > 0
  return true
}

/**
 *
 * @param {*} policy
 * @returns {'IAMAction' | 'Target'}
 */
export const getPolicyType = (policy) => {
  return policy.Type
}

export const K8RSRCS_PRIINCPAL_DISPLAY_TYPE_MAP = {
  POD: 'Pods',
  DEPLOY: 'Deployments',
  SVC: 'Services',
  STS: 'StatefulSets'
}

/**
 *
 * @param {string} principal The policy prinicpal from rule.Principal
 * @returns {{
 * type: 'POD' |'STS'|'DEPLOYMENTS'
 * name: string
 *  }[]}
 */
export const getK8NamespaceRsrcsFromPrincipal = (principal) => {
  if (!principal) return []
  const splited = principal.split(',')
  //@ts-ignore
  return splited.map((rsrc) => {
    const [type, name] = rsrc.split(':')
    return {
      type: type.toUpperCase(),
      name: name
    }
  })
}

/**
 * Check is a policy was created for kubenamespace resources like pods, etc
 * @param {*} policy Policy to check for
 * @returns {boolean}
 */
export const isPolicyKubenamespaceRsrcs = (policy) => {
  const { KubeAccess } = getTargetsPolicyRules(policy)
  const isK8RsrcPolicy = KubeAccess.some((rule) => {
    return rule.ObjectRef.RefKind === 'KubeNamespace' && rule.Principal
  })
  return isK8RsrcPolicy
}

export const K8RSRCS_TYPE_ICON_TYPE_MAP = {
  Deployments: 'KUBE_DEPLOYMENT',
  StatefulSets: 'KUBE_STATEFULLSET',
  Services: 'KUBE_SERVICES',
  Pods: 'KUBE_POD'
}

export const getKubeType = (type) => {
  if (type === 'POD') {
    return `KUBE_POD`
  }
  if (type === 'STS') {
    return `KUBE_STATEFULLSET`
  }
  if (type === 'DEPLOY') {
    return `KUBE_DEPLOYMENT`
  }
  if (type === 'SVC') {
    return `KUBE_SERVICES`
  }
}

/**
 * Get the rule for this resource
 * @param {import('types').ObjectRef} rsrcRef The ref of the resource
 * @param {*} policy
 * @returns {{ ruleName:string, rule:any }}
 */
export const getRsrcPolicyRule = (rsrcRef, policy) => {
  const rulesMap = getTargetsPolicyRules(policy)

  const ruleMap = {
    ruleName: '',
    rule: null
  }

  for (const ruleName in rulesMap) {
    const rules = rulesMap[ruleName]
    rules.some((rule) => {
      if (createRsrcKey(rule.ObjectRef) === createRsrcKey(rsrcRef)) {
        ruleMap.rule = rule
        ruleMap.ruleName = ruleName
        return true
      }
    })
  }

  return ruleMap
}

export const getPolicyDisplayName = (policy) => {
  if (!policy) return
  const bundle = getObjectFromRedux(policy.BundleRef)
  if (bundle) return getResourceName(bundle)
  return policy.GivenName
}

/**
 * Returns the rule which matches the tags of `rsrc` in `policy`
 * @param {*} rsrc The resource whose tag rule is needed
 * @param {*} policy The tag pac policy
 */
export const getRsrcTagPolicyRule = (rsrc, policy) => {
  const matchRules = []
  let rules = []

  const TAGS = getRsrcTagsMap(rsrc)

  const actionMap = getPolicyActionMap(policy)

  switch (rsrc.ObjectMeta.Kind) {
    case 'Server':
      rules = actionMap.SSH?.PolicyRule || []
      break
    case 'Application':
      rules = actionMap.HTTP?.PolicyRule || []
      break
  }

  rules.forEach((rule) => {
    const attributes = rule.Attributes.Map || {}
    const tags = Object.keys(attributes)
    for (const key of tags) {
      const [kindLowerCase, tagType, tagKey, tagValue] = key.split(':')
      // rsrc and pac tag kind *MUST* match
      if (kindLowerCase !== rsrc.ObjectMeta.Kind.toLowerCase()) return
      // get the appropriate tag map
      const rsrcTagMap = TAGS[tagType] || {}
      // split the values on rsrcTagMap[tagKey] with ',' Note: case insensitive
      const splitValues = (rsrcTagMap[tagKey] || '').toLowerCase().split(',')
      //* check if the pac's tag value matches the resources tags
      if (splitValues.includes(tagValue)) matchRules.push(rule)
    }
  })

  return matchRules
}

export const getRsrcTagsMap = (rsrc, toLowerCase = true) => {
  const tags = {
    ctag: keysToLowerCase(getRsrcTags(rsrc)),
    label: keysToLowerCase(getResourcePolicyTags(rsrc))
  }

  if (toLowerCase) return tags

  return {
    ctag: getRsrcTags(rsrc),
    label: getResourcePolicyTags(rsrc)
  }
}

/**
 * Checks if the policy is tag based or not.
 * @param {*} policy
 * @returns {boolean}
 */
export const isTagBasedPolicy = (policy) => {
  if (!policy) return
  const SSHRules = policy.Spec.ActionMap.SSH?.PolicyRule || []
  const HTTPRules = policy.Spec.ActionMap.HTTP?.PolicyRule || []

  const rules = [...SSHRules, ...HTTPRules]

  // loop over rules, check if any key in `rule.Attributes.Map` includes keyword: server or application
  return rules.some((rule) =>
    Object.keys(rule.Attributes.Map).some((e) => e.includes('server') || e.includes('application'))
  )
}

/**
 * Get tags in the policy
 * @param {*} tagPolicy
 * @returns {{ Kind: 'Server'|'Application', tagType:'ctag'|'label', tagKey:string, tagValue:string }[]}
 */
export const getTagPolicyTags = (tagPolicy) => {
  if (!isTagBasedPolicy(tagPolicy)) return []
  const tags = []

  const SSHRules = tagPolicy.Spec.ActionMap.SSH?.PolicyRule || []
  const HTTPRules = tagPolicy.Spec.ActionMap.HTTP?.PolicyRule || []

  const rules = [...SSHRules, ...HTTPRules]
console.log('[tag](rules):', rules)
  rules.forEach((rule) => {
    const tagsMap = rule.Attributes.Map
    for (const tagsString in tagsMap) {
      const splits = tagsString.split(':')
      tags.push({
        Kind: TAGS_KEY_KIND_STRING_MAP[splits[0]],
        tagType: splits[1],
        tagKey: splits[2],
        tagValue: splits[3]
      })
    }
  })
  return tags
}

export const TAGS_KEY_KIND_STRING_MAP = {
  server: 'Server',
  application: 'Application'
}
