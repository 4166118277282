import { getResourceName } from 'features/resources'
import { USER_CREDENTIALS_STATUS } from 'features/settings/utils'
import { reduxApiClient } from 'infra'
import React from 'react'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal } from 'V2Components'

export const DiscardUserCredentials = ({ userCredential, credKeyToDiscard, onCancel }) => {
  const handleDiscard = async () => {
    const data = structuredClone(userCredential)
    const map = data.PublicKeyCredentialMap.PublicKeyCredentialMap

    // approve status
    map[credKeyToDiscard] = { ...map[credKeyToDiscard], Status: USER_CREDENTIALS_STATUS.discard }
    data.PublicKeyCredentialMap.PublicKeyCredentialMap = map

    await reduxApiClient('user-credentials').update(data)
    enqueueNotification('Passkey has been discarded successfully!', 'info')
    onCancel()
  }

  const name = getResourceName(userCredential)

  return (
    <FullScreenAlertModal
      actionButtonText='Discard'
      actionButtonVariant='grayRed'
      loadingMessage={`Discarding Passkey`}
      showModal
      onActionClick={handleDiscard}
      onCancel={onCancel}
      alertMessage={
        <>
          Are you sure you want to discard passkey of
          <strong className='mx-1' title={name}>
            {name.substring(0, 21)}
            {name.length > 21 && '...'}
          </strong>
          ?
        </>
      }
    />
  )
}
