import { Grid, makeStyles } from '@material-ui/core'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { cn } from 'Utils/Helpers'
import { PaginationView } from 'V2Components'
import {
  createRsrcKey,
  getResourceCloudType,
  getRsrcAccountRef,
  isResourceManaged,
  useResourcesViewContext
} from 'features/resources'
import {
  getTagsDefaultSuggestions,
  ResourceQueryFilterMap,
  ResourceTagSuggesstionsMap
} from 'features/tags'
import { isPrivateResource, isPrivateServer } from 'features/targets'
import { Button, Label, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ResourceFilterHeader } from './components/ResourceFilterHeader'
import { rsrcSearchDataMatch } from './utils'
import useAppView from 'Core/Hooks/useAppView'
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  resource: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  filters: {
    backgroundColor: '#F9FBFC',
    width: '336px',
    position: 'relative',
    overflow: 'wrap',
    minHeight: '100vh'
  },
  items: {
    backgroundColor: 'white',
    padding: theme.spacing(1.5, 2),
    width: 'calc(100% - 336px)'
  },
  catalogText: {
    fontWeight: 500,
    fontSize: '24px'
  },
  filterText: {
    fontSize: '18px'
  },
  filterDropDown: {
    width: '307px',
    marginLeft: '24px'
  },
  groupFilters: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  searchInput: {
    width: '303px'
  },
  viewSelect: {
    marginLeft: '25px',
    display: 'inline-block'
  },
  resourceItemCont: {
    marginTop: theme.spacing()
  }
}))

/**
 * @typedef {{ start: number, end:number }} PaginationIndex
 * @param {{
 * HeaderFilter?: any
 * setRenderedResources?: ((rsrcs: object[]) => void),
 * cloudOptions:string[],
 * activeCloudType:string,
 * setActiveCloudType:((value) => void),
 * disableAccountControls?: boolean,
 * accountType?: string,
 * accountID?: string,
 * FilterComponent: any,
 * resources: any[],
 * children?:any,
 * renderCardComponent:((rsrc) => void),
 * render4Cards?:boolean,
 * filterSaveKey:string
 * }} param0
 * @returns
 */
function ResourcesView({
  disableAccountControls,
  resources,
  children,
  FilterComponent,
  renderCardComponent,
  activeCloudType,
  setActiveCloudType,
  cloudOptions,
  HeaderFilter,
  render4Cards,
  accountID,
  filterSaveKey
}) {
  const classes = useStyles()
  const { dispatchThunks, getObjectRef } = useMultiSlice(['accountList'])
  // Account filter, '' for All accounts
  const [selectedDropdownKey, setSelectedDropdownKey] = useState('')
  // Search filter
  const [searchKey, setSearchKey] = useState('')
  const [selectedTagsForPolicy, setSelectedTagsForPolicy] = useState([])
  const history = useHistory()
  const { applySearchQuery, getSuggestionsData, getParsedQueryKeyItems } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (e, k) =>
      rsrcSearchDataMatch(e, k, { account: getObjectRef(getRsrcAccountRef(e)) }),
    queryMapKeyExtarctor: 'ObjectMeta.Kind',
    queryMap: ResourceQueryFilterMap,
    suggesstionsMap: ResourceTagSuggesstionsMap
  })
  const { appView } = useAppView()
  const { dropDownItems } = useResourcesViewContext()

  if (accountID && selectedDropdownKey !== accountID) setSelectedDropdownKey(accountID)

  /**
   *
   * @param {any[]} rsrcs
   * @param {'AWS' | 'GCP' | 'AZURE' | 'PRIVATE' | string} accountType
   * @returns {any[]}
   */
  const getResourcesByAccountType = (rsrcs, accountType) => {
    const list = []
    rsrcs.forEach((rsrc) => {
      const isPrivateRsrc = isPrivateResource(rsrc)
      const rsrcCloudType = getResourceCloudType(rsrc)

      if (accountType.includes('AWS') && rsrcCloudType === 'AWS') list.push(rsrc)
      if (accountType.includes('GCP') && rsrcCloudType === 'GCP') list.push(rsrc)
      if (accountType.includes('AZURE') && rsrcCloudType === 'AZURE') list.push(rsrc)

      if (accountType === 'PRIVATE' && isPrivateRsrc) list.push(rsrc)
    })
    return list
  }

  const rsrcsList = !activeCloudType
    ? resources
    : getResourcesByAccountType(resources, activeCloudType) // Account brand filtered resources

  const filteredResources = (() => {
    const _rsrcs = []
    const searchFilteredList = applySearchQuery(rsrcsList)
    searchFilteredList.forEach((rsrc) => {
      const account = getObjectRef(getRsrcAccountRef(rsrc))
      //  check if it is private resource
      if (activeCloudType === 'PRIVATE' && isPrivateResource(rsrc)) return _rsrcs.push(rsrc)
      // Check if the account id is non-empty and check if the resource belongs to this account
      if (!account) return
      if (selectedDropdownKey && createRsrcKey(account) !== selectedDropdownKey) return
      _rsrcs.push(rsrc)
    })

    return _rsrcs
  })()

  const tagMap = getParsedQueryKeyItems()
  const tag = tagMap.tag || tagMap.ptag

  const disableAddBtn = () => {
    // disable when tag has no managed resources
    if (filteredResources.filter(isResourceManaged).length === 0) return true
    // disable when tag is already selected
    if (selectedTagsForPolicy.includes(tagMap.tag || tagMap.ptag)) return true
    // show when tag has key and value
    if (tag?.split('!').filter((e) => e.trim().length).length > 1) return false
    return true
  }

  const showTagPolicyBtns = () => {
    if (selectedTagsForPolicy.length) return true
    if (appView === 'user') return false
    if (tag?.split('!').filter((e) => e.trim().length).length > 1) return true
    if (filteredResources.some((e) => e.ObjectMeta.Kind !== 'Server')) return false
    else return false
  }

  const handleCreateTagPolicyCreate = () => {
    const currentRsrcKindMap = {}

    filteredResources.forEach((r) => (currentRsrcKindMap[r.ObjectMeta.Kind] = true))

    const tagsStr = JSON.stringify(selectedTagsForPolicy)

    history.push(
      `/admin/tags/policy/create?${tagMap.tag ? 'tag' : 'ptag'}=${tagsStr}&scope=${JSON.stringify(
        Object.keys(currentRsrcKindMap)
      )}`
    )
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  return (
    <div className='flex justify-center -ml-8 -mr-5 -mt-5'>
      <Grid container>
        <Grid className={classes.filters} item>
          {FilterComponent && <FilterComponent rsrcs={filteredResources} />}
        </Grid>
        <Grid className={classes.items} item>
          <div className='flex flex-col'>
            <ResourceFilterHeader
              filterSaveKey={filterSaveKey}
              suggestions={getSuggestionsData(rsrcsList)}
              defaultSuggestions={getTagsDefaultSuggestions(rsrcsList)}
              activeCloudType={activeCloudType}
              cloudOptions={cloudOptions}
              disableAccountControls={disableAccountControls}
              dropDownItems={dropDownItems}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              selectedDropdownValue={selectedDropdownKey}
              setActiveCloudType={setActiveCloudType}
              setSelectedDropdownValue={setSelectedDropdownKey}
              HeaderFilter={HeaderFilter}
              AdvancedSearchComponent={
                showTagPolicyBtns() && (
                  <div className='relative z-[1000]'>
                    <div className='flex gap-2'>
                      <Button
                        disabled={selectedTagsForPolicy.length === 0}
                        size='sm'
                        onClick={handleCreateTagPolicyCreate}
                        variant='primary'
                      >
                        Create Tag Policy
                      </Button>
                      <Button
                        icon={faPlus}
                        disabled={disableAddBtn()}
                        size='sm'
                        onClick={() => {
                          setSelectedTagsForPolicy((s) => [tagMap.tag || tagMap.ptag, ...s])
                        }}
                        variant='grayBlue'
                      >
                        Add
                      </Button>
                    </div>
                    {selectedTagsForPolicy.length > 0 && (
                      <div className='absolute min-w-[230px] bg-white rounded-sm drop-shadow p-2 w-[auto] flex gap-2 flex-wrap mt-2'>
                        {selectedTagsForPolicy.map((t) => {
                          return (
                            <Typography
                              className='w-[100%] flex justify-between items-center !text-[13px]'
                              key={t}
                              variant='body-regular'
                            >
                              {t}
                              <span
                                onClick={() => {
                                  setSelectedTagsForPolicy((s) => s.filter((e) => e !== t))
                                }}
                                className='px-2 ml-2 rounded bg-gray-100 cursor-pointer hover:opacity-[0.5]'
                              >
                                <FontAwesomeIcon color='#f87171' icon={faMinus} />
                              </span>
                            </Typography>
                          )
                        })}
                      </div>
                    )}
                  </div>
                )
              }
            />
            <PaginationView
              itemsCount={12}
              data={filteredResources}
              renderFunction={renderCardComponent}
              WrapperComponent={({ children }) => (
                <div
                  className={cn(
                    {
                      'grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3': !render4Cards,
                      'grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4': render4Cards
                    },
                    'grid gap-8 mt-4'
                  )}
                >
                  {children}
                </div>
              )}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export * from './utils'
export { ResourcesView }
