import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { SearchInput } from 'V2Components'
import { getPolicyType, isTagBasedPolicy } from 'features/policy/utils'
import React, { useState } from 'react'
import { useEditPolicyModulesContext } from '../../EditPolicyModulesProvider'
import { IAMAction } from './components/IAMAction'
import { Target } from './components/Target'
import { TagBased } from './components/TagBased'
import { cn } from 'Utils/Helpers'

const SelectResources = ({}) => {
  const [searchKey, setSearchKey] = useState('')

  const { policy } = useEditPolicyModulesContext()

  const isTagPolicy = isTagBasedPolicy(policy)

  useHTMLBodyScrollbar(true)

  const policyType = getPolicyType(policy)

  return (
    <div className='relative'>
      {!isTagPolicy && (
        <div className='pb-2 border-b mb-2 px-5 -mx-5 fixed w-[626px] bg-white z-[580]'>
          <SearchInput onChange={setSearchKey} searchKey={searchKey} />
        </div>
      )}
      <div className={cn({ 'pt-14': !isTagPolicy, 'border-t pt-4': isTagPolicy })}>
        {isTagPolicy && <TagBased />}
        {!isTagPolicy && (
          <>
            {policyType === 'IAMAction' && <IAMAction searchKey={searchKey} />}
            {policyType === 'Target' && <Target searchKey={searchKey} />}
          </>
        )}
      </div>
    </div>
  )
}

export { SelectResources }
