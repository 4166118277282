import { faCheck, faPen } from '@fortawesome/pro-solid-svg-icons'
import {
  createRsrcKey,
  getResourceName,
  getRsrcAccountRef,
  getRsrcIcon,
  isResourceManaged
} from 'features/resources'
import { getTargetsPolicyRules } from 'features/targets'
import { createDataSelectorHook, useObjectRef } from 'infra/redux'
import { Button, GenericResourceRow, IconButton, Label, TextInput, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useEditPolicyModulesContext } from '../../../../EditPolicyModulesProvider'

const useSlices = createDataSelectorHook(['serverList', 'applicationList'])

export const TagBased = () => {
  const { policy, attributesMap, setAttributesMap } = useEditPolicyModulesContext()
  const [showPrincipalInput, setShowPrincipalInput] = useState(false)

  const { slices } = useSlices()
  const { getObjectRef } = useObjectRef(['accountList'])

  const getResources = () => {
    const addedRsrcsMap = {}
    const userSelectedTags = attributesMap.policyTags.items // string array: name!rdp, tagKey!tagValue
    const tagType = attributesMap.policyTags.type // ctag || label
    const kind = attributesMap.policyTags.kind //Server||Application

    const list = kind === 'Server' ? slices.serverList : slices.applicationList

    const rsrcs = []

    list.forEach((rsrc) => {
      if (addedRsrcsMap[createRsrcKey(rsrc)]) return
      addedRsrcsMap[createRsrcKey(rsrc)] = createRsrcKey(rsrc)

      if (rsrc.ObjectMeta.Kind !== 'Application' && !isResourceManaged(rsrc)) return
      const tags = tagType === 'ctag' ? rsrc.ObjectMeta.CTags.Map : rsrc.ObjectMeta.Labels.Map

      for (const tagKey in tags) {
        const tagValue = tags[tagKey].toLowerCase()
        const tagValues = tagValue.split(',')
        const generatedTags = tagValues.map((e) => `${tagKey.toLowerCase()}!${e}`)
        if (userSelectedTags.find((t) => generatedTags.includes(t))) rsrcs.push(rsrc)
      }
    })

    return rsrcs
  }

  useEffect(() => {
    const { SSH } = getTargetsPolicyRules(policy)
    // update principal
    const Principal = SSH[0]?.Principal || ''
    setAttributesMap({ Principal }, true)
  }, [])

  const Principal = attributesMap.Principal || ''

  const resources = getResources()

  const isServer = resources.length > 0 && resources[0].ObjectMeta.Kind === 'Server'

  return (
    <div>
      <div className='flex justify-between items-center mb-4'>
        <Typography variant='h4-regular'>Resources</Typography>
        <div className='flex items-center justify-center gap-4'>
          {isServer && (
            <>
              {showPrincipalInput && (
                <TextInput
                  placeholder='Enter Principal'
                  sx={{ width: '200px' }}
                  value={attributesMap.Principal}
                  onChange={(e) => {
                    setAttributesMap({ Principal: e.target.value }, true)
                  }}
                />
              )}
              {showPrincipalInput && (
                <div className='flex gap-2 items-center justify-center'>
                  <IconButton
                    onClick={() => setShowPrincipalInput(false)}
                    icon={faCheck}
                    variant='gray'
                  />
                </div>
              )}
              {!showPrincipalInput && (
                <Button icon={faPen} onClick={() => setShowPrincipalInput(true)} variant='grayBlue'>
                  Edit Server Principal
                </Button>
              )}
            </>
          )}
        </div>
      </div>

      {resources.map((rsrc) => {
        const Icon = getRsrcIcon(rsrc)
        const account = getObjectRef(getRsrcAccountRef(rsrc))
        return (
          <div className='w-[100%] mb-2' key={createRsrcKey(rsrc)}>
            <GenericResourceRow
              children2={<></>}
              caption={getResourceName(account)}
              accountName=''
              className='w-[100%]'
              children1={
                isServer && (
                  <div className='flex gap-2 w-[100%] justify-end items-center'>
                    {Principal && <Label variant='grayBlue' text={`Principal: ${Principal}`} />}
                    {!Principal && <Label variant='warning' text='No Principal' />}
                  </div>
                )
              }
              region=''
              resourceIcon={<Icon className='rounded' />}
              title={getResourceName(rsrc)}
            />
          </div>
        )
      })}
    </div>
  )
}
