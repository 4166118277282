import React from 'react'
import { Route } from 'react-router'
import { Remediations } from '../view/Remediations'
import { RemediationDetails } from '../view/RemediationsDetails'
import { IdaGraph } from '../view'

const IdaRoutes = () => {
  return (
    <>
      <Route exact path={[`/admin/idaanalyzer`]} component={Remediations} />
      <Route
        exact
        path={[`/admin/idaanalyzer/idagraph/:kind/:accountRefID`]}
        component={IdaGraph}
      />
      <Route
        exact
        path={[`/admin/idaanalyzer/:kind/:id/:cloudType`]}
        component={RemediationDetails}
      />
    </>
  )
}

export { IdaRoutes }
