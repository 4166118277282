import { faExclamationTriangle, faKey, faUser, faUserGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createDataSelectorHook } from 'infra/redux'
import moment from 'moment'
import { Button, DataTable, RiskIndicator, TargetIcon, Tooltip } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ShimmerTableRow } from 'Utils/ShimmerEffect'
import _ from 'lodash'
import { filterRowsBySearchQuery, getRiskLevel } from 'features/IdentityAnalyzer/utils'

const useSlices = createDataSelectorHook(['iamGroups', 'accountList'])
const GroupsTable = ({ searchQuery }) => {
  const { slices } = useSlices()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [tableRows, setTableRows] = useState([])
  const [accountType, setAccountType] = useState('')
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 30,
    page: 0
  })

  const getAccountType = (id) => {
    const account = slices.accountList.find((account) => account.ObjectMeta.ID === id)
    return account?.Spec?.Type
  }

  const getFindingObj = (data) => {
    const cloudType = getAccountType(data?.Account?.RefID)
    if (cloudType === 'AWS') {
      return data?.AwsGroupSpec
    } else {
      return data?.GcpGroupSpec
    }
  }

  useEffect(() => {
    if (!slices || slices.iamGroups.length === 0) {
      setIsLoading(true)
    }

    const getAccountObj = (accountRefID) => {
      const account = slices?.accountList?.find((account) => account.ObjectMeta.ID === accountRefID)
      return account?.Spec?.DisplayName || account?.Spec?.Description
    }

    if (slices?.iamGroups?.length > 0 && tableRows.length === 0) {
      const rows = slices.iamGroups
        .slice()
        .sort((a, b) => {
          const dateA = moment(a.ObjectMeta.CreatedAt)
          const dateB = moment(b.ObjectMeta.CreatedAt)
          return dateA.isBefore(dateB) ? 1 : -1
        })
        .map((obj, index) => ({
          id: index + 1,
          platform: getAccountType(obj.Spec.Account.RefID),
          name: obj.Spec.Name,
          createdate:
            obj.Spec.AwsGroupSpec.CreatedAt !== '' &&
            moment(obj.Spec.AwsGroupSpec.CreatedAt).format('MM-DD-YY HH:mm:ss'),
          lastused: '',
          account: getAccountObj(obj.Spec.Account.RefID),
          riskResources: obj.Spec.RiskScore,
          accountRef: obj.ObjectMeta.ID,
          finding: getFindingObj(obj?.Spec)
        }))

      setTableRows(rows)
      setIsLoading(false)
    }
  }, [slices])

  const handleGraphRedirection = (accountRef) => {
    history.push(`/admin/idaanalyzer/idagraph/IamGroup/${encodeURIComponent(accountRef)}`)
  }

  const filteredRows = filterRowsBySearchQuery(tableRows, searchQuery)

  const handleRedirectRemedyDetails = (id, cloudType) => {
    history.push(
      `/admin/idaanalyzer/IamGroup/${encodeURIComponent(id)}/${encodeURIComponent(cloudType)}`
    )
  }

  const generateFindingsLabels = (data) => {
    const userData = data.row.finding
    const labels = []
    const addedLabels = new Set()

    // Analyzer Findings Labels for GCP
    if (data.row.platform === 'GCP') {
      const gcpAnalyzerFindings = Object.keys(userData?.AnalyzerFindings?.Map || {}).length
        ? Object.fromEntries(
            Object.entries(userData?.AnalyzerFindings?.Map).map(([key, value]) => {
              try {
                return [key, JSON.parse(value)] // Parse each value
              } catch (error) {
                console.error(`Failed to parse value for key "${key}":`, error)
                return [key, value] // Use original value if parsing fails
              }
            })
          )
        : {}

      gcpAnalyzerFindings &&
        Object.keys(gcpAnalyzerFindings).forEach((key) => {
          const finding = gcpAnalyzerFindings[key]
          // UnusedPermission Label (Clickable)
          if (finding.Type === 'UnusedPermission' && !addedLabels.has('UnusedPermission')) {
            labels.push(
              <Tooltip title='Unused Permission' key={`UnusedPermission-${key}`}>
                <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </span>
              </Tooltip>
            )
            addedLabels.add('UnusedPermission')
          }

          // UnusedIAMRole label
          if (finding.Type === 'UnusedIAMRole' && !addedLabels.has('UnusedIAMRole')) {
            labels.push(
              <Tooltip key={`UnusedIAMRole-${key}`} title='Unused Iam Role'>
                <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
                  <FontAwesomeIcon icon={faUserGear} />
                </span>
              </Tooltip>
            )
            addedLabels.add('UnusedIAMRole')
          }
        })
    }

    return labels
  }

  return (
    <div className='relative'>
      <DataTable
        autoHeight={true}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <>
                  <span className='mr-2'>
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <p className='font-medium'>{param.value}</p>
                </>
              ),
            width: 300
          },
          {
            field: 'createdate',
            headerName: 'Creation Date',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <Tooltip title='Date and Time Format Is MM-DD-YY HH-MM-SS'>
                  <p className='font-medium'>{param.value}</p>
                </Tooltip>
              ),
            width: 220
          },
          {
            field: 'account',
            headerName: 'Account',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <p className='font-medium'>{param.value}</p>,
            width: 220
          },
          {
            field: 'platform',
            headerName: 'Platforms',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <TargetIcon width='30px' type={param.value} />,
            width: 150
          },
          {
            field: 'riskResources',
            headerName: 'Risk Resources',
            renderCell: (params) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <RiskIndicator variant={getRiskLevel(params.value)} />
              ),
            width: 160
          },
          {
            field: 'graph',
            headerName: 'Graph',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <div className='bg-white shadow-md rounded-full w-[35px] h-[35px] flex items-center justify-center'>
                  <img
                    onClick={() => handleGraphRedirection(param.row.accountRef)}
                    src='img/icons/organization-chart.png'
                    alt=''
                    className='w-5 cursor-pointer'
                  />
                </div>
              ),
            width: 130
          },
          {
            field: 'finding',
            headerName: 'Findings',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <>{generateFindingsLabels(param)}</>,
            width: 250
          },
          {
            field: 'accountRef',
            headerName: 'Recommended Action',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <>
                  {generateFindingsLabels(param).length > 0 && (
                    <span
                      onClick={() => handleRedirectRemedyDetails(param.value, param.row.platform)}
                    >
                      <Button variant='secondary'>Action</Button>
                    </span>
                  )}
                </>
              ),
            width: 180
          }
        ]}
        rows={
          isLoading
            ? Array(1).fill({
                id: '',
                name: '',
                createdate: '',
                platforms: '',
                riskResources: ''
              })
            : filteredRows
        }
      />
    </div>
  )
}

export { GroupsTable }
