import { getResourceName } from 'features/resources'
import { reduxApiClient } from 'infra'
import React from 'react'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal } from 'V2Components'

export const DeleteUserCredentials = ({ userCredential, credKeyToDelete, onCancel }) => {
  const handleDelete = async () => {
    const data = structuredClone(userCredential)
    const map = data.PublicKeyCredentialMap.PublicKeyCredentialMap

    // approve status
    delete map[credKeyToDelete]
    data.PublicKeyCredentialMap.PublicKeyCredentialMap = map

    await reduxApiClient('user-credentials').update(data)
    enqueueNotification('Passkey has been deleted successfully!', 'info')
    onCancel()
  }

  const name = getResourceName(userCredential)

  return (
    <FullScreenAlertModal
      actionButtonText='Delete'
      actionButtonVariant='danger'
      loadingMessage={`Deleting Passkey`}
      showModal
      onActionClick={handleDelete}
      onCancel={onCancel}
      alertMessage={
        <>
          Are you sure you want to delete passkey of
          <strong className='mx-1' title={name}>
            {name.substring(0, 21)}
            {name.length > 21 && '...'}
          </strong>
          ?
        </>
      }
    />
  )
}
