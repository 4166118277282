import useAppView from 'Core/Hooks/useAppView'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { useUser } from 'Core/Hooks/useUser'
import { dateSortComparator, getResourceName } from 'features/resources'
import { getUserCredentialsPublicKeys, USER_CREDENTIALS_STATUS } from '../../utils'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import { Button, IconButton, Label, ResponsiveTable, Tooltip, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { SearchInput } from 'V2Components'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { ApproveUserCredentials } from './components/ApproveUserCredentials'
import { DiscardUserCredentials } from './components/DiscardUserCredentials'
import { DeleteUserCredentials } from './components/DeleteUserCredentials'
import moment from 'moment'
import { EditPasskeyName } from './components/EditPasskeyName'

const useSlices = createDataSelectorHook(['userCredentials', 'userList'])

export const DevicePassKeys = () => {
  const [searchKey, setSearchKey] = useState('')
  const [showApproveUserCreds, setShowApproveUserCreds] = useState(false)
  const [showDiscardUserCreds, setShowDiscardUserCreds] = useState(false)
  const [showDeletePasskey, setShowDeletePasskey] = useState(false)
  const [showEditPasskey, setShowEditPasskey] = useState(false)

  const [selectedUsrCreds, setSelectedUsrCreds] = useState(null)
  const [selectedCredKey, setSelectedCredKey] = useState('')

  const { slices } = useSlices()

  const { user: currentUser, currentUserIsAdmin, isMFAEnabled } = useUser()
  const { appView } = useAppView()

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (row, queryKey) => {
      if (!queryKey) return true
      const { id, user, device, status } = row
      const all = `${id}${status}${device}${user.name}${user.email}`.toLowerCase()
      return all.includes(queryKey)
    }
  })

  const getTableData = () => {
    const data = []
    slices.userCredentials.map((usrCreds) => {
      const creds = getUserCredentialsPublicKeys(usrCreds)

      creds.forEach((cred) => {
        const user = _.find(slices.userList, { ObjectMeta: { Name: usrCreds.ObjectMeta.Name } })
        //on user view show only the logged in user passkeys
        if (appView === 'user' && usrCreds.ObjectMeta.Name !== currentUser.ObjectMeta.Name) return
        data.push({
          id: cred.ID,
          name: cred.PassKeyName || '',
          user: {
            name: user ? getResourceName(user) : usrCreds.ObjectMeta.Name,
            email: usrCreds.ObjectMeta.Name,
            isYou: currentUserIsAdmin
              ? usrCreds.ObjectMeta.Name === currentUser.ObjectMeta.Name
              : false
          },
          device: cred.DeviceName,
          status: cred.Status,
          lastUsed: cred.LastUsed,
          editKeyName: {
            onEdit() {
              setShowEditPasskey(true)
              setSelectedUsrCreds(usrCreds)
              setSelectedCredKey(cred.key)
            }
          },
          approve: {
            allowApproval: cred.Status === USER_CREDENTIALS_STATUS.pending,
            allowDiscard: cred.Status === USER_CREDENTIALS_STATUS.pending,
            onApprove() {
              setShowApproveUserCreds(true)
              setSelectedUsrCreds(usrCreds)
              /**
               ** getUserCredentialsPublicKeys adds the object key,
               ** of the map object usrCreds.PublicKeyCredentialMap.PublicKeyCredentialMap
               ** We use this key to directly map into credential in the Approval process
               */
              setSelectedCredKey(cred.key)
            },
            onDiscard() {
              setShowDiscardUserCreds(true)
              setSelectedUsrCreds(usrCreds)
              setSelectedCredKey(cred.key)
            }
          },
          delete: {
            onDelete() {
              setShowDeletePasskey(true)
              setSelectedUsrCreds(usrCreds)
              setSelectedCredKey(cred.key)
            }
          }
        })
      })
    })

    return data
  }

  if (!isMFAEnabled)
    return (
      <Typography variant='body-regular'>
        MFA is disabled. Enable it from <em>Settings</em> to view Passkeys.
      </Typography>
    )

  return (
    <div>
      <div className='flex justify-between items-center mb-6'>
        <Typography variant='h2'>Passkeys</Typography>
        <div className='flex gap-8'>
          <SearchInput sx={{ width: '303px' }} searchKey={searchKey} onChange={setSearchKey} />
        </div>
      </div>
      <ResponsiveTable
        scrollX='1200px'
        data={applySearchQuery(getTableData())}
        columns={getColumns().filter((e) => !!e)}
      />
      {showApproveUserCreds && selectedUsrCreds && (
        <ApproveUserCredentials
          credKeyToApprove={selectedCredKey}
          userCredential={selectedUsrCreds}
          onCancel={() => {
            setShowApproveUserCreds(false)
            setSelectedUsrCreds(null)
          }}
        />
      )}
      {showDiscardUserCreds && selectedUsrCreds && (
        <DiscardUserCredentials
          credKeyToDiscard={selectedCredKey}
          userCredential={selectedUsrCreds}
          onCancel={() => {
            setShowDiscardUserCreds(false)
            setSelectedUsrCreds(null)
          }}
        />
      )}
      {showDeletePasskey && selectedUsrCreds && (
        <DeleteUserCredentials
          credKeyToDelete={selectedCredKey}
          userCredential={selectedUsrCreds}
          onCancel={() => {
            setShowDiscardUserCreds(false)
            setSelectedUsrCreds(null)
          }}
        />
      )}
      {showEditPasskey && selectedUsrCreds && (
        <EditPasskeyName
          credKeyToEdit={selectedCredKey}
          userCredential={selectedUsrCreds}
          onCancel={() => {
            setShowEditPasskey(false)
            setSelectedUsrCreds(null)
          }}
        />
      )}
    </div>
  )
}

/**
 *
 * @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
const getColumns = () => [
  {
    dataIndex: 'name',
    title: 'Name',
    width: 250,
    align: 'left',
    sorter: (v1, v2) => v1.name.localeCompare(v2.name),
    render(value) {
      if (!value) return <Label variant='gray' text='None' />
      return <Typography variant='body-regular'>{value}</Typography>
    }
  },
  {
    dataIndex: 'user',
    title: 'User',
    width: 250,
    align: 'left',
    sorter: (v1, v2) => v1.user.name.localeCompare(v2.user.name),
    render(value) {
      return (
        <div className='flex items-end justify-start gap-1'>
          <Typography variant='body-regular'>{value.name}</Typography>
          <Typography variant='caption-regular'>{value.email}</Typography>
          {value.isYou && <strong className='text-[#3267D6]'>(You)</strong>}
        </div>
      )
    }
  },
  {
    dataIndex: 'device',
    title: 'Device',
    width: 250,
    align: 'left',
    sorter: (v1, v2) => v1.device.localeCompare(v2.device),
    render(value) {
      if (!value) return <Label variant='gray' text='None' />
      return <Typography variant='body-regular'>{value}</Typography>
    }
  },
  {
    dataIndex: 'lastUsed',
    title: 'Last Used',
    align: 'left',
    width: 250,
    sorter: (v1, v2) => dateSortComparator(v1.lastUsed, v2.lastUsed),
    render(value) {
      return (
        <Typography variant='body-regular'>
          {moment(value).format('MMMM Do YYYY, h:mm A')}
        </Typography>
      )
    }
  },
  {
    dataIndex: 'status',
    title: 'Status',
    align: 'center',
    width: 100,
    sorter: (v1, v2) => v1.status.localeCompare(v2.status),
    render(value) {
      if (value === USER_CREDENTIALS_STATUS.approved)
        return <Label variant='grayBlue' text='Approved' />
      if (value === USER_CREDENTIALS_STATUS.discard)
        return <Label variant='danger' text='Discarded' />
      return <Label variant='gray' text='Pending' />
    }
  },
  {
    dataIndex: 'id',
    title: 'ID',
    width: 100,
    align: 'left',
    sorter: (v1, v2) => v1.id.localeCompare(v2.id),
    render(value) {
      return (
        <Tooltip title={value} arrow>
          <div>
            <Typography variant='body-regular'>{value.slice(0,12)}</Typography>
          </div>
        </Tooltip>
      )
    }
  },
  {
    dataIndex: 'approve',
    title: '',
    align: 'center',
    width: 130,
    render(value) {
      return (
        <div className='flex gap-1'>
          {value.allowApproval && (
            <Button className='mx-auto' onClick={value.onApprove} variant='grayBlue'>
              Approve
            </Button>
          )}
          {value.allowDiscard && (
            <Button className='mx-auto' onClick={value.onDiscard} variant='grayRed'>
              Discard
            </Button>
          )}
        </div>
      )
    }
  },
  {
    dataIndex: 'editKeyName',
    title: '',
    align: 'right',
    width: 200,
    render(value) {
      return (
        <div className='flex gap-1'>
          <Button variant='grayBlue' onClick={value.onEdit}>
            Edit Passkey Name
          </Button>
        </div>
      )
    }
  },
  {
    dataIndex: 'delete',
    title: '',
    align: 'right',
    width: 35,
    render(value) {
      return (
        <div className='flex gap-1'>
          <IconButton icon={faTrash} variant='grayRed' onClick={value.onDelete} />
        </div>
      )
    }
  }
]
