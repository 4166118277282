import useAppView from 'Core/Hooks/useAppView'
import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { SummaryHeader } from 'V2Components'
import { getRsrcTags } from 'features/iamResources'
import {
  getResourceName,
  getResourcePolicyTags,
  getRsrcAccountRef,
  getRsrcIcon,
  reverseRsrcKey
} from 'features/resources'
import {
  TagsCreatePolicyProvider,
  TagsURLQueryProvider,
  useTagsURLQueryContext
} from 'features/tags'
import { MapKindToSliceName, createDataSelectorHook, useObjectRef } from 'infra/redux'
import { createRsrcKey } from 'infra/redux/reducers'
import { Button, SelectDropDown, TabGroup } from 'procyon-ui'
import React, { useState } from 'react'
import { Config } from './components/Config'
import { ResourcesTab } from './components/Tabs/ResourcesTab'
import { UsersTab } from './components/Tabs/UsersTab'

const useSlices = createDataSelectorHook([
  'awsResources',
  'gcpResources',
  'azureResources',
  'serverList',
  'applicationList'
])

const CreateTagPolicy = () => {
  const [selectedAccountKey, setSelectedAccountKey] = useState('')

  const { slices } = useSlices()
  const { appView } = useAppView()
  const canGoBackFn = useCanGoBack(`/${appView}/resources`)
  const canGoBack = useCanGoBack()

  const { scopeKinds, tagType, tagItems, routeTag } = useTagsURLQueryContext()

  const { getObjectRef } = useObjectRef(['accountList'])

  const getResources = () => {
    const list = []
    scopeKinds.forEach((kind) => {
      const sliceName = MapKindToSliceName[kind]
      if (slices[sliceName]) list.push(...slices[sliceName])
    })
    const matchMap = {}
    const matchRsrcs = []

    // handle multiple tags selected
    tagItems.forEach(({ tagKey, tagValue }) => {
      list.forEach((rsrc) => {
        if (matchMap[createRsrcKey(rsrc)]) return
        matchMap[createRsrcKey(rsrc)] = true
        const tagsMap = tagType === 'tag' ? getRsrcTags(rsrc) : getResourcePolicyTags(rsrc)
        if (typeof tagsMap[tagKey] !== 'undefined') {
          if (tagValue) {
            const splits = (tagsMap[tagKey] || '').toLowerCase().split(',')
            if (splits.includes(tagValue.toLowerCase())) matchRsrcs.push(rsrc)
          } else {
            matchRsrcs.push(rsrc)
          }
        }
      })
    })

    return matchRsrcs
  }
  const rsrcs = getResources()
  console.log('[tag](match):', rsrcs)

  const getAccountsDropdownItems = () => {
    const map = {}
    rsrcs.forEach((e) => {
      const accountRef = getRsrcAccountRef(e)
      if (accountRef && accountRef.RefID !== '0') map[createRsrcKey(accountRef)] = true
    })

    const accounts = getObjectRef(Object.keys(map).map(reverseRsrcKey))

    return accounts.map((acc) => {
      const Icon = getRsrcIcon(acc)
      return {
        label: (
          <div className='flex gap-2'>
            <Icon />
            {getResourceName(acc)}
          </div>
        ),
        value: createRsrcKey(acc),
        type: acc.Spec.Type
      }
    })
  }

  const accountDropdownItems = getAccountsDropdownItems()

  // Initialize selected account dropdown when emoty
  if (accountDropdownItems.length && !selectedAccountKey) {
    setSelectedAccountKey(accountDropdownItems[0].value)
  }

  return (
    <TagsCreatePolicyProvider
      selectedAccountRef={reverseRsrcKey(selectedAccountKey)}
      resources={rsrcs}
    >
      <div className='-mt-2'>
        <SummaryHeader
          breadCrumbsItems={[
            {
              label: canGoBack ? 'Go Back' : 'Resources',
              onClick: canGoBackFn
            },
            {
              label: routeTag
            }
          ]}
          actions={
            <>
              <SelectDropDown
                menuItems={accountDropdownItems}
                onChange={(e) => {
                  setSelectedAccountKey(e.target.value)
                }}
                value={selectedAccountKey}
              />
              {/* @ts-ignore */}
              <Button onClick={canGoBackFn} size='md' variant='gray'>
                Cancel
              </Button>
            </>
          }
          rsrcName={`Create Policy for ${routeTag}}`}
        />
        <div className='gap-4 flex'>
          <div className='w-1/2 border-r pr-[58px] mt-4'>
            <TabGroup
              tabs={[
                {
                  label: 'Resources',
                  tabContent: <ResourcesTab />
                },
                {
                  label: 'Users',
                  tabContent: <UsersTab />
                }
              ]}
            />
          </div>
          <div className='w-1/2 pt-4 pl-[58px]'>
            <Config />
          </div>
        </div>
      </div>
    </TagsCreatePolicyProvider>
  )
}

const Wrapped = () => {
  return (
    <TagsURLQueryProvider>
      <CreateTagPolicy />
    </TagsURLQueryProvider>
  )
}

export { Wrapped as CreateTagPolicy }
