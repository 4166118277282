import { faEdit } from '@fortawesome/pro-solid-svg-icons'
import { reduxApiClient } from 'infra'
import _ from 'lodash'
import { TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal } from 'V2Components'

export const EditPasskeyName = ({ userCredential, credKeyToEdit, onCancel }) => {
  const orgPasskeyName = _.get(
    userCredential,
    `PublicKeyCredentialMap.PublicKeyCredentialMap.${credKeyToEdit}.PassKeyName`,
    ''
  )
  const [passkeyName, setPasskeyName] = useState(orgPasskeyName)

  const handleUpdate = async () => {
    const data = structuredClone(userCredential)
    const map = data.PublicKeyCredentialMap.PublicKeyCredentialMap

    // approve status
    map[credKeyToEdit] = { ...map[credKeyToEdit], PassKeyName: passkeyName }
    data.PublicKeyCredentialMap.PublicKeyCredentialMap = map
    await reduxApiClient('user-credentials').update(data)
    enqueueNotification('Passkey has been approved successfully!', 'info')
    onCancel()
  }

  return (
    <FullScreenAlertModal
      icon={faEdit}
      actionButtonText='Update'
      actionButtonVariant='primary'
      loadingMessage={`Updating Passkey Name`}
      showModal
      onActionClick={handleUpdate}
      onCancel={onCancel}
      disableAction={!passkeyName || passkeyName === orgPasskeyName}
      alertMessage={
        <>
          <Typography className='mb-6' variant='h4-regular'>
            Edit Passkey Name
          </Typography>
          <TextInput
            label='Passkey Name'
            sx={{ width: '100%' }}
            value={passkeyName}
            onChange={(e) => setPasskeyName(e.target.value)}
          />
        </>
      }
    />
  )
}
