import { makeStyles } from '@material-ui/core'
import useAppView from 'Core/Hooks/useAppView'
import { SideMenu as SM } from 'procyon-ui'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { getAdminViewSidebarItems } from './adminMenuItems'
import { getUserViewRoutes } from './userMenuItems'
import { useUser } from 'Core/Hooks/useUser'
import classNames from 'classnames'
import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { createDataSelectorHook } from 'infra/redux'

const useStyles = makeStyles((theme) => ({
  root: {},
  sideMenuWrapper: {
    paddingTop: theme.spacing(4),
    height: '100%',
    position: 'fixed',
    borderRight: '1px solid #D0D9EA',
    zIndex: 1000,
    backgroundColor: 'white'
  },
  overflow: {
    overflow: 'auto'
  }
}))

const useSlices = createDataSelectorHook(['tenantprofiles'])

function Sidemenu({ onSideMenuStateChange, sideMenuState }) {
  const classes = useStyles()
  const history = useHistory()
  const { slices } = useSlices()
  const [pathname, setPathname] = useState(null)
  const [collapsed, setCollapsed] = useState(true)
  const [keepOpen, setKeepOpen] = useState(false)
  const { viewMode } = useAppView()
  const { isAuditor, currentUserIsAdmin, isAgentlessMode, user, isMFAEnabled } = useUser()
  const tenantProfile = slices.tenantprofiles.find(
    (e) => e.ObjectMeta.Name === user.ObjectMeta.Tenant
  )

  const IsIdaVisible = tenantProfile?.Spec?.IDAConfig?.ciem

  const menuItems = useMemo(() => {
    const items = []
    const unParsedItems =
      viewMode === 'admin'
        ? getAdminViewSidebarItems({ history, isMFAEnabled })
        : getUserViewRoutes({
            history,
            showSessionLogs: currentUserIsAdmin || isAuditor,
            isMFAEnabled
          })
    let selectedFlag = false
    unParsedItems.forEach((e) => {
      if (!e) return
      /**
       * Some urls needs to be hidden on agentless mode
       * hence if hideOnAgentless is true then, we dont show the tab
       */
      if (isAgentlessMode && e._meta?.hideOnAgentless) return

      /**
       * show the ida page only if tenant ida config ciem is true
       */

      if (e._meta?.showIDA && !IsIdaVisible) return

      /** Flag property for active/selected menu item */
      let selected = false
      /**
       * We need to check if this item's active route matches the current pathname,
       * and set `selected` property to true/false for sidemenu items
       */
      if (!selectedFlag && e._meta && e._meta.itemActiveRoutes) {
        if (pathname && pathname.startsWith(e._meta.itemActiveRoutes)) {
          /** This menu item is active */
          selected = true
          selectedFlag = true
        }
      }
      /** Delete the `_meta` field which is not needed by sidebar */
      delete e._meta
      items.push({ ...e, selected })
    })
    return items
  }, [viewMode, pathname, isAuditor, isAgentlessMode, IsIdaVisible])

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setPathname(location.pathname)
    })
    return unlisten
  }, [])

  useEffect(() => {
    if (sideMenuState !== keepOpen) onSideMenuStateChange && onSideMenuStateChange(keepOpen)
  }, [sideMenuState, keepOpen])

  useHTMLBodyScrollbar(!collapsed)

  return (
    <div className={classes.root}>
      <div className={classNames(classes.sideMenuWrapper, { [classes.overflow]: !collapsed })}>
        <SM
          collapsed={collapsed || keepOpen}
          onMouseEnter={() => setCollapsed(false)}
          onMouseLeave={() => setCollapsed(true)}
          keepOpen={keepOpen}
          onClickKeepOpen={() => setKeepOpen((s) => !s)}
          // @ts-ignore
          menuItems={menuItems}
        />
      </div>
    </div>
  )
}

export { Sidemenu }
