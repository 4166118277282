import axios from 'axios'
import { useURLQuery } from 'Core/Hooks/useURLQuery'
import { useEffect, useState } from 'react'

function bufferDecode(base64Url) {
  const padding = '='.repeat((4 - (base64Url.length % 4)) % 4)
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const rawData = atob(base64)
  return Uint8Array.from(rawData, (c) => c.charCodeAt(0)).buffer
}

function bufferEncode(value) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(value)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

export const useWebAuthnLogin = () => {
  const [progressMessage, setProgressMessage] = useState('')
  const [progressStatus, setProgressStatus] = useState('running') // running || success || errored

  const search = useURLQuery()

  const name = search.get('name') || ''
  const tenant = search.get('tenant') || ''
  const namespace = search.get('namespace') || ''
  const nonce = search.get('nonce') || ''
  const deviceName = search.get('deviceName') || ''


  async function bringFocus() {
    if (!document.hasFocus()) {
        console.log('Document is not focused. Bringing it into focus...');
        window.focus(); 
        await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  async function loginUser() {
    setProgressMessage('Logging in User')
    const response = await axios.post(
      '/pubapi/' + tenant + '/v1/webauthn',
      JSON.stringify({
        ObjectMeta: {
          Name: name,
          Namespace: namespace,
          Tenant: tenant
        },
        type: 'VerificationReq',
        nonce: nonce
      })
    )

    if (response?.data?.verificationResponse?.status == "INITIATE_REGISTRATION") {
      let e = new Error("Please go ahead with the registration flow");
      e.name = "NotFoundError"
      throw e;
    }

    const credentialRequestOptions = response.data.optionResponse

    credentialRequestOptions.publicKeyCredentialRequestOptions.challenge = bufferDecode(
      credentialRequestOptions.publicKeyCredentialRequestOptions.challenge
    )
    credentialRequestOptions.publicKeyCredentialRequestOptions.allowCredentials.forEach(function (
      listItem
    ) {
      listItem.id = bufferDecode(listItem.id)
    })

    setProgressMessage('Fetching Credentials')

    const assertion = await navigator.credentials.get({
      publicKey: credentialRequestOptions.publicKeyCredentialRequestOptions
    })

    const authData = assertion.response.authenticatorData
    const clientDataJSON = assertion.response.clientDataJSON
    const rawId = assertion.rawId
    const sig = assertion.response.signature
    const userHandle = assertion.response.userHandle

    setProgressMessage('Finishing Login')

    const postResponse = await axios.post(
      '/pubapi/' + tenant + '/v1/webauthn',
      JSON.stringify({
        ObjectMeta: {
          Name: name,
          Namespace: namespace,
          Tenant: tenant
        },
        type: 'VerificationResp',
        nonce: nonce,
        verificationLoginRequest: {
          id: assertion.id,
          username: name,
          tenant: tenant,
          namespace: namespace,
          rawId: bufferEncode(rawId),
          type: assertion.type,
          response: {
            authenticatorData: bufferEncode(authData),
            clientDataJSON: bufferEncode(clientDataJSON),
            signature: bufferEncode(sig),
            userHandle: bufferEncode(userHandle)
          }
        }
      })
    )

    if (postResponse.data?.verificationResponse?.status == 'CREDENTIAL_STATUS_PENDING') {
      setProgressMessage('Your Passkey is not approved')
      setProgressStatus('error')
      return
    }

    if (postResponse.data?.verificationResponse?.status == 'CREDENTIAL_STATUS_REJECTED') {
      setProgressMessage('Your Passkey is discarded!')
      setProgressStatus('error')
      return
    }

    setProgressMessage('Logged in Successfully!')
    setProgressStatus('success')
    return postResponse.data
  }

  async function registerUser() {
    setProgressMessage('Registering User')
    const response = await axios.post(
      '/pubapi/' + tenant + '/v1/webauthn',
      JSON.stringify({
        ObjectMeta: {
          Name: name,
          Namespace: namespace,
          Tenant: tenant
        },
        type: 'OptionReq',
        nonce: nonce
      })
    )

    const credentialCreationOptions = response.data.optionResponse

    credentialCreationOptions.publicKeyCredentialCreationOptions.challenge = bufferDecode(
      credentialCreationOptions.publicKeyCredentialCreationOptions.challenge
    )

    credentialCreationOptions.publicKeyCredentialCreationOptions.user.id = bufferDecode(
      credentialCreationOptions.publicKeyCredentialCreationOptions.user.id
    )

    if (credentialCreationOptions.publicKeyCredentialCreationOptions.excludeCredentials) {
      for (
        let i = 0;
        i < credentialCreationOptions.publicKeyCredentialCreationOptions.excludeCredentials.length;
        i++
      ) {
        credentialCreationOptions.publicKeyCredentialCreationOptions.excludeCredentials[i].id =
          bufferDecode(
            credentialCreationOptions.publicKeyCredentialCreationOptions.excludeCredentials[i].id
          )
      }
    }

    if (credentialCreationOptions.publicKeyCredentialCreationOptions.authenticatorSelection) {
      delete credentialCreationOptions.publicKeyCredentialCreationOptions.authenticatorSelection
        .authenticatorAttachment
      credentialCreationOptions.publicKeyCredentialCreationOptions.authenticatorSelection.requireResidentKey =
        credentialCreationOptions.publicKeyCredentialCreationOptions.authenticatorSelection
          .requireResidentKey == 'true'
    }

    setProgressMessage('Creating Credentials')

    const credentials = await navigator.credentials.create({
      publicKey: credentialCreationOptions.publicKeyCredentialCreationOptions
    })

    const attestationObject = credentials.response.attestationObject
    const clientDataJSON = credentials.response.clientDataJSON
    const rawId = credentials.rawId

    setProgressMessage('Finishing User Registration')

    const postResponse = await axios.post(
      '/pubapi/' + tenant + '/v1/webauthn',
      JSON.stringify({
        ObjectMeta: {
          Name: name,
          Namespace: namespace,
          Tenant: tenant
        },
        type: 'OptionResp',
        nonce: nonce,
        verificationRegRequest: {
          username: name,
          id: credentials.id,
          tenant: tenant,
          namespace: namespace,
          rawId: bufferEncode(rawId),
          type: credentials.type,
          devicename: deviceName,
          response: {
            attestationObject: bufferEncode(attestationObject),
            clientDataJSON: bufferEncode(clientDataJSON)
          }
        }
      })
    )

    setProgressMessage('User Registration Successful')
    return postResponse.data
  }

  useEffect(() => {
    if (!name || !tenant || !namespace) {
      setProgressMessage('Name, Tenant or Namespace missing from URL!')
      setProgressStatus('error')
      return
    }

    const init = async () => {
      try {
        await bringFocus()
        await loginUser()
      } catch (error) {
        try {
          if (error.name == 'NotAllowedError' || error.name == 'NotFoundError') {
            await registerUser()
            await loginUser()
          } else {
            setProgressMessage('Critical Error: ' + error.message)
            setProgressStatus('error')
            console.error('[webauthn](error):')
            console.error(error)
          }
        } catch (error) {
          setProgressMessage('Unknown Error: ' + error.message)
          console.error('[webauthn](error):')
          console.error(error)
          setProgressStatus('error')
        }
      }
    }

    init()
  }, [])

  return { progressMessage, progressStatus }
}