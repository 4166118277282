import React, { useEffect, useRef, useState } from 'react'
import { useEditPolicyModulesContext } from '../../../../EditPolicyModulesProvider'
import { getTagPolicyTags } from 'features/policy/utils'
import { createDataSelectorHook } from 'infra/redux'
import { AdvancedSearch } from 'V2Components'
import {
  getTagsDefaultSuggestions,
  ResourceQueryFilterMap,
  ResourceTagSuggesstionsMap
} from 'features/tags'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { getResourceName } from 'features/resources'
import { Button, Typography } from 'procyon-ui'
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'

const useSlices = createDataSelectorHook(['serverList', 'applicationList'])

export const TagBased = () => {
  const { policy, setAttributesMap, attributesMap } = useEditPolicyModulesContext()
  const { slices } = useSlices()
  const [searchKey, setSearchKey] = useState('')
  const [selectedTagsForPolicy, setSelectedTagsForPolicy] = useState([])
  const { getSuggestionsData, getParsedQueryKeyItems } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (e, k) => {
      const name = getResourceName(e)
      return name.toLowerCase().includes(k)
    },
    queryMapKeyExtarctor: 'ObjectMeta.Kind',
    queryMap: ResourceQueryFilterMap,
    suggesstionsMap: ResourceTagSuggesstionsMap
  })

  const init = useRef(false)

  const tagMap = getParsedQueryKeyItems()
  console.log('[tag](map):', tagMap)
  const disableAddButton = () => {
    const tagMap = getParsedQueryKeyItems()

    const tag = tagMap.tag || tagMap.ptag
    if (selectedTagsForPolicy.includes(tag?.toLowerCase())) return true
    if (tag?.split('!').filter((e) => e.trim().length).length > 1) return false

    return true
  }

  const updateTagPolicyItems = (items) => {
    setAttributesMap(
      {
        policyTags: {
          ...attributesMap.policyTags,
          items
        }
      },
      true
    )
  }

  const tags = getTagPolicyTags(policy)

  const getRsrcList = () => {
    const kind = tags[0].Kind
    if (kind === 'Application') return slices.applicationList
    if (kind === 'Server') return slices.serverList
    return []
  }

  useEffect(() => {
    if (tags.length && !init.current) {
      init.current = true

      const items = tags.map((e) => `${e.tagKey}!${e.tagValue}`)
      const isSame = _.isEqual(items, attributesMap.policyTags?.items)
      // check if user modified the attributes then, populate the selected items
      if (!isSame && attributesMap.policyTags?.items.length) {
        setSelectedTagsForPolicy(attributesMap.policyTags.items)
        return
      }
      setAttributesMap(
        {
          policyTags: {
            items,
            type: tags[0].tagType,
            kind: tags[0]?.Kind || 'Server'
          }
        },
        true
      )

      setSelectedTagsForPolicy(items)
    }
  }, [policy])

  return (
    <div className='flex gap-2'>
      <div className='w-1/2'>
        <div className='relative'>
          <div className='flex justify-between items-center gap-2'>
            <Typography variant='h4-regular'>Selected Tags</Typography>
            <Button
              icon={faPlus}
              disabled={disableAddButton()}
              size='sm'
              onClick={() => {
                const t = tagMap.tag || tagMap.ptag
                setSelectedTagsForPolicy((s) => {
                  updateTagPolicyItems([t, ...s])
                  return [t, ...s]
                })
              }}
              variant='grayBlue'
            >
              Add
            </Button>
          </div>
          {selectedTagsForPolicy.length > 0 && (
            <div className='absolute w-[100%] p-1 rounded-sm flex flex-wrap'>
              {selectedTagsForPolicy.map((t) => {
                return (
                  <Typography
                    className='w-[100%] bg-gray-50 flex justify-between items-center !text-[13px] p-1 mb-2'
                    key={t}
                    variant='body-regular'
                  >
                    {t}
                    <span
                      onClick={() => {
                        setSelectedTagsForPolicy((s) => {
                          const d = s.filter((e) => e.toLowerCase() !== t.toLowerCase())
                          updateTagPolicyItems(d)
                          return d
                        })
                      }}
                      className='px-2 ml-2 rounded bg-gray-100 cursor-pointer hover:opacity-[0.5]'
                    >
                      <FontAwesomeIcon color='#f87171' icon={faMinus} />
                    </span>
                  </Typography>
                )
              })}
            </div>
          )}
        </div>
      </div>
      <AdvancedSearch
        shadow={false}
        alwaysVisible
        wrapperClassName='w-1/2'
        width='100%'
        defaultSuggestions={getTagsDefaultSuggestions(getRsrcList())}
        suggestions={getSuggestionsData(getRsrcList())}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
      />
    </div>
  )
}
