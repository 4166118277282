import useMultiDispatch from 'Core/Hooks/useMultiDispatch'
import { TabGroup, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import InstallAgent from 'Views/SettingsPage/Components/Agent'
import { ApproverPolicy } from './components/Tabs/ApproverPolicy'
import { Profile } from './components/Tabs/Profile'
import { ReadPolicy } from './components/Tabs/ReadPolicy'
import { ReleaseNotes } from './components/Tabs/ReleaseNotes'
import { TagPolicy } from './components/Tabs/TagPolicy'

function Settings() {
  const { selectDispatch } = useMultiDispatch(['tenantprofiles'])
  //@ts-ignore
  const { tab } = useParams()
  const [activeTab, setActiveTab] = useState(tab)

  const TAB_INDEX_MAP = {
    profile: 0,
    'tag policy': 1,
    'approver policy': 2,
    'read policy': 3,
    'device passkeys': 4,
    'app downloads': 5,
    releasenote: 6
  }

  const TAB_NAME_MAP = {
    0: 'profile',
    1: 'tag policy',
    2: 'approver policy',
    3: 'read policy',
    4: 'device passkeys',
    5: 'app downloads',
    6: 'releasenote'
  }

  useEffect(() => {
    if (tab) {
      setActiveTab(tab)
    }
  }, [tab])

  useEffect(() => {
    selectDispatch(['tenantprofiles', 'orgs', 'tagPolicys', 'userList', 'groupList', 'approvers'])
  }, [])

  return (
    <div className=''>
      <div className='bg-[#F9FBFC] -mt-12 -mx-[18px] py-12 px-4 mb-6'>
        <Typography variant='h2'>Settings</Typography>
      </div>
      <TabGroup
        controllable={tab === 'releasenote'}
        activeIndex={tab === 'releasenote' ? TAB_INDEX_MAP[activeTab] : 0}
        tabContentHeight='100vh'
        onChangeTab={(_, v) => {
          setActiveTab(TAB_NAME_MAP[v])
        }}
        tabs={[
          {
            label: 'Profile',
            tabContent: <Profile />
          },
          {
            label: 'Tag Policy',
            tabContent: <TagPolicy />
          },
          {
            label: 'Approver Policy',
            tabContent: <ApproverPolicy />
          },
          {
            label: 'Read Policy',
            tabContent: <ReadPolicy />
          },
          {
            label: 'App Downloads',
            tabContent: <InstallAgent />
          },
          {
            label: 'Release Note',
            tabContent: <ReleaseNotes />
          }
        ]}
      />
    </div>
  )
}

export { Settings }
