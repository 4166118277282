export const INITIAL_FORM = {
  name: '',
  regex: '',
  appRoleGroup: '',
  appGroup: '',
  awsResourceGroup: '',
  azureResourceGroup: '',
  databaseGroup: '',
  gatewayGroup: '',
  gcpResourceGroup: '',
  kubeClusterGroup: '',
  kubeNameSpaceGroup: '',
  kafkaGroup: '',
  projectGroup: '',
  rdpServerGroup: '',
  serverGroup: ''
}

export const ResourceKinds = [
  {
    label: 'Application',
    kind: 'Application'
  },
  {
    label: 'AppRole',
    kind: 'AppRole'
  },
  {
    label: 'Aws Resource',
    kind: 'AwsResource'
  },
  {
    label: 'Azure Resource',
    kind: 'AzureResource'
  },
  {
    label: 'Database',
    kind: 'Database'
  },
  {
    label: 'Gateway',
    kind: 'MedusaNode'
  },
  {
    label: 'Gcp Resource',
    kind: 'GcpResource'
  },
  {
    label: 'Kube Cluster',
    kind: 'KubeCluster'
  },
  {
    label: 'Kube Namespace',
    kind: 'KubeNamespace'
  },
  {
    label: 'Kafka',
    kind: 'Kafka'
  },
  {
    label: 'Project',
    kind: 'Project'
  },
  {
    label: 'RDP Server',
    kind: 'RDPServer'
  },
  {
    label: 'Server',
    kind: 'Server'
  }
]

export const customTagKeys = [
  {
    label: 'App Group',
    kind: 'appgrp'
  },
  {
    label: 'Proxy Group',
    kind: 'proxygrp'
  },
  {
    label: 'Policy Group',
    kind: 'policygrp'
  },
  {
    label: 'Server Group',
    kind: 'servergrp'
  },
  {
    label: 'Read Group',
    kind: 'readgrp'
  }
]

export const USER_CREDENTIALS_STATUS = {
  pending: 'PENDING',
  discard: 'DISCARD',
  approved: 'APPROVED'
}